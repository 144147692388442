import React from 'react'
import Layout from '../../components/de/Layout/Layout'
import About from '../../components/de/About/About'

const AboutPage = () => (
  <Layout
    title='Über uns  | the.good.code; - Globales Team von Technologiespezialisten'
    description='Lernen Sie the.good.code kennen, ein Team von Technologieexperten mit internationaler Erfahrung, die für weltweit führende Unternehmen wie Microsoft, Intel, P&G und innovative Start-ups arbeiten. Entdecken Sie unsere Mission, Vision und Werte.'
  >
    <About />
  </Layout>
)

export default AboutPage

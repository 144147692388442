import React from 'react'
import { ourValues } from '../consts'
import ContentContainer from '../../../common/Layout/ContentContainer'
import TextWithDots from '../../../common/TextWithDots/TextWithDots'
import FadeInUpAnimation from '../../../common/FadeInUpAnimation/FadeInUpAnimation'
import useLineAnimation from '../../../common/hooks/useLineAnimation'
import Value from '../../../common/About/OurValues/Value'

const OurValues = () => {
  const valuesRef = useLineAnimation()

  return (
    <ContentContainer
      as='article'
      className='article-padding'
    >
      <FadeInUpAnimation>
        <TextWithDots className='mb-12'>Unsere Werte</TextWithDots>
      </FadeInUpAnimation>
      <div ref={valuesRef}>
        {ourValues.map((value) => (
          <Value
            key={value.title}
            {...value}
          />
        ))}
      </div>
    </ContentContainer>
  )
}

export default OurValues

import React from 'react'
import SectionHeader from '../../common/Header/SectionHeader'
import ContentContainer from '../../common/Layout/ContentContainer'
import OurValues from './OurValues/OurValues'
import FadeInUpAnimation from '../../common/FadeInUpAnimation/FadeInUpAnimation'
import GoodTeam from './GoodTeam/GoodTeam'
import Management from './Management'

const About = () => (
  <section>
    <SectionHeader wordsToType={['Über uns']} />
    <FadeInUpAnimation>
      <ContentContainer
        as='p'
        className='text-4xl leading-snug font-light lg:text-6xl'
      >
        the.good.code wurde im Jahr 2021 von 3 Technologieexperten gegründet,
        die langjährige Berufserfahrung von Weltmarktführern wie Microsoft,
        Intel, P&G und innovativen Startups mitbringen.
      </ContentContainer>
    </FadeInUpAnimation>
    <Management />
    <GoodTeam />
    <OurValues />
  </section>
)

export default About

export const ourValues = [
  {
    title: 'Menschen',
    description:
      'Positive Beziehungen sind lebenswichtig für eine langfristige Zusammenarbeit. Wir selektieren Berater, die am besten zum jeweiligen Kunden passen in Bezug auf Fertigkeiten, Sprache und Kultur, um ein optimales Arbeitsumfeld zu schaffen.'
  },
  {
    title: 'Resultate für Ihr Unternehmen',
    description:
      'Wir fokussieren uns auf Ihre Unternehmensziele, wir berücksichtigen alle notwendigen Faktoren und Ihre Anforderungen. Wir nehmen keine Abkürzungen und wir betreiben kein Overengineering.'
  },
  {
    title: 'Hohe Qualität',
    description:
      'Unsere Spezialisten haben im Durchschnitt mehr als 10 Jahre Erfahrung und liefern höchste Qualität unterstützt durch professionelle interne Prozesse.'
  },
  {
    title: 'Umwelt',
    description:
      'Unser Einfluß auf die Umwelt ist wichtiger Teil unserer Entscheidungsfindung bei der Auswahl der eingesetzten Technologien. Wir arbeiten nur mit energie-optimierten Sprachen und Frameworks in Kombination mit Shared Cloud Infrastrukturen basierend auf erneuerbaren Energien'
  }
]

import React from 'react'
import ContentContainer from '../../common/Layout/ContentContainer'
import Person from '../../common/About/Person'
import { graphql, useStaticQuery } from 'gatsby'
import { map, sortBy } from 'lodash'
import FadeInUpAnimation from '../../common/FadeInUpAnimation/FadeInUpAnimation'
import boardPhoto from '../../../images/team/board-photo.jpg'

const query = graphql`
  query {
    allFile(
      filter: {
        sourceInstanceName: { eq: "content" }
        relativeDirectory: { eq: "about/management" }
        name: { regex: "/[.]de/" }
      }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              id
              email
              img {
                childImageSharp {
                  gatsbyImageData(
                    width: 900
                    formats: [AUTO, WEBP, AVIF]
                    quality: 80
                  )
                }
              }
              name
              position
              role
            }
          }
        }
      }
    }
  }
`

const Management = () => {
  const {
    allFile: { edges }
  } = useStaticQuery(query)

  const management = map(edges, 'node.childMarkdownRemark.frontmatter')
  const sortedManagement = sortBy(management, (manager) => manager.id)

  return (
    <ContentContainer className='flex flex-col gap-14 article-padding '>
      <img
        src={boardPhoto}
        alt='management'
        className='rounded-xl block max-lg:hidden'
      />
      <div className='grid grid-cols-1 gap-y-14 gap-x-5 lg:grid-cols-3'>
        {sortedManagement.map((manager, idx) => (
          <FadeInUpAnimation
            key={idx}
            className='col-span-1 text-center opacity-25'
            delay={idx * 0.2}
          >
            <Person {...manager} />
          </FadeInUpAnimation>
        ))}
      </div>
    </ContentContainer>
  )
}

export default Management
